<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">{{ meeting_id === '' ? '新增会议' : '编辑会议' }}</p>
            <p class="btn-common" @click="saveMeeting">保存</p>
        </div>
        <el-form :model="meetingInfo" :rules="rules" ref="meetingForm" class="flex-1-overflow-y">
            <el-form-item label="会议名称" prop="name">
                <el-input v-model="meetingInfo.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="会议类型" prop="type">
                <el-select v-model="meetingInfo.type" placeholder="请选择">
                    <el-option label="单日会议" value="单日会议"></el-option>
                    <el-option label="多日会议" value="多日会议"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="meetingInfo.type == '单日会议' ? '当天日期' : '开始日期'" prop="start_date">
                <el-date-picker v-model="meetingInfo.start_date" type="date" value-format="timestamp"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否开放">
                <el-select v-model="meetingInfo.check_ticket" placeholder="请选择">
                    <el-option label="是" value="NO" />
                <el-option label="否" value="YES" />
            </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="start_time" v-if="meetingInfo.type == '单日会议'">
                <el-time-picker v-model="meetingInfo.start_time" format="HH:mm" value-format="HH:mm"
                    placeholder="请选择时间"></el-time-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="end_time" v-if="meetingInfo.type == '单日会议'">
                <el-time-picker v-model="meetingInfo.end_time" format="HH:mm" value-format="HH:mm"
                    placeholder="请选择时间"></el-time-picker>
            </el-form-item>
            <el-form-item label="结束日期" prop="end_date" v-if="meetingInfo.type == '多日会议'">
                <el-date-picker v-model="meetingInfo.end_date" type="date" value-format="timestamp" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="会议时长" prop="duration" v-if="meetingInfo.type == '单日会议'">
                <el-input-number v-model="meetingInfo.duration" placeholder="请输入"></el-input-number> &nbsp;（单位分钟）
            </el-form-item> -->
            <el-form-item label="会议地址" prop="address">
                <el-input v-model="meetingInfo.address" placeholder="请输入"></el-input>
                <el-input v-model="meetingInfo.geo_address" disabled></el-input>
                <div style="width: 100%;height: 400px;margin-top: 10px; border: 1px solid #dcdfe6;border-radius: 4px; position: relative; z-index: 10;"
                    id="gaodemap">
                    <div class="mapsearch">
                        <el-select no-data-text="暂无数据" v-model="positionValue" filterable remote reserve-keyword
                            placeholder="请输入城市+位置信息搜索" :remote-method="remoteMethod" :loading="positionLoading"
                            @change="changeselmap" popper-class="projectManagePopper" style="background: #fff">
                            <el-option v-for="(item, index) in positionOptions" :key="index" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="会议主题" prop="topic">
                <el-input v-model="meetingInfo.topic" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="会议规模" prop="attend_peoples">
                <el-input-number v-model="meetingInfo.attend_peoples" placeholder="请输入"></el-input-number>人
            </el-form-item>
            <el-form-item label="面向观众" prop="audiences">
                <el-input v-model="meetingInfo.audiences" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="透明底Logo">
                <el-upload action="/" :show-file-list="false" :before-upload="(file) => beforeTransLogoUpload(file)">
                    <img v-if="meetingInfo.logo_transbg_url" :src="meetingInfo.logo_transbg_url" class="logo-uploader">
                    <i v-else class="el-icon-plus logo-uploader2"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="不透明底Logo">
                <el-upload action="/" :show-file-list="false" :before-upload="(file) => beforeLogoUpload(file)">
                    <img v-if="meetingInfo.logo_url" :src="meetingInfo.logo_url" class="logo-uploader">
                    <i v-else class="el-icon-plus logo-uploader2"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="">
                <label>会议简介</label>
                <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor" v-model="meetingInfo.intro"
                    :options="editorOption">
                </quill-editor>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import '../../../assets/common/common.css'
import {
    getQiniuToken,
    updateMeeting,
    getMeetingInfo
} from '../../../api/api'
import * as qiniu from "qiniu-js"
import VueTagsInput from '@johmun/vue-tags-input'
import {
    getUserId,
    getUserType
} from '../../../utils/store'
import {
    formatDate
} from '../../../utils/date'

const geocoder = new window.AMap.Geocoder({
    city: "全国",
});

export default ({
    name: 'index',
    components: {
        VueTagsInput
    },
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            meetingInfo: {
                id: '',
                organizer_id: '',
                name: '',
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                duration: '',
                intro: '',
                logo_url: '',
                address: '',
                topic: '',
                audiences: '',
                attend_peoples: '',
                type: '',
                geo_address: '',
                check_ticket: 'NO',
                lon_lat: '',
                logo_transbg_url: ''
            },
            rules: {
                end_time: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                audiences: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                end_date: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                attend_peoples: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                topic: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                start_date: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                start_time: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                duration: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                intro: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            //地图相关的
            positionLoading: false,
            positionValue: '',
            positionOptions: [],
            amap1: null,
            amarker: null,
            meetingRemindInfo: {
                meeting_id: '',
                after_register: '',
                before_one_day: '',
                before_one_hour: ''
            },
        }
    },
    mounted() {
        if (this.meeting_id != '') {
            this.fetchData()
        } else {
            this.refreshMap()
        }
    },
    methods: {
        fetchData() {
            getMeetingInfo(this.meeting_id).then((res) => {
                let row = res.data.data
                this.meetingInfo = {
                    id: row._id.$id,
                    organizer_id: row.organizer_id,
                    name: row.name,
                    start_date: row.start_date * 1000,
                    end_date: row.end_date ? row.end_date * 1000 : '',
                    start_time: row.start_time,
                    duration: row.duration,
                    intro: row.intro,
                    logo_url: row.logo_url,
                    address: row.address,
                    topic: row.topic,
                    attend_peoples: row.attend_peoples,
                    type: row.type,
                    geo_address: row.geo_address,
                    lon_lat: row.lon_lat,
                    audiences: row.audiences,
                    logo_transbg_url: row.logo_transbg_url,
                    check_ticket: row.check_ticket,
                    end_time: ''
                }
                if (this.meetingInfo.type == '单日会议') {
                    let start_time = this.meetingInfo.start_time
                    let startTimes = start_time.split(':')
                    let hours = this.formatTime(startTimes[0]) * 60 * 60
                    let mins = this.formatTime(startTimes[1]) * 60
                    let seconds = this.formatTime(startTimes[2])
                    let startSeconds = hours + mins + seconds
                    var totalSeconds = startSeconds + this.meetingInfo.duration

                    var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
                    totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
                    var minute = Math.floor(totalSeconds / 60); // 计算分钟
                    totalSeconds %= 60; // 取余得到不足1分钟的秒数

                    this.meetingInfo.end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)
                }
                this.refreshMap()
            })
        },
        refreshMap() {
            this.$nextTick(() => {
                this.initmap();
                setTimeout(() => {
                    if (this.amap1) {
                        this.amap1.on("click", (e) => {
                            if (this.amarker) {
                                this.amap1.remove(this.amarker);
                            }
                            this.amarker = new window.AMap.Marker({
                                position: [e.lnglat.getLng(), e.lnglat.getLat()],
                            });
                            this.amap1.add(this.amarker);
                            this.getgeocoder([e.lnglat.lng, e.lnglat.lat]);
                            this.meetingInfo.lon_lat =
                                e.lnglat.getLng() + "," + e.lnglat.getLat();
                        });
                    }
                }, 200)
            })
        },

        formatShowDate(time) {
            return formatDate(new Date(time * 1000), 'yyyy/MM/dd')
        },
        async beforeTransLogoUpload(file) {
            const isPNG = file.type === 'image/png';//透明底只能是png格式的图片
            if (!isPNG) {
                this.$message.error('上传透明底LOGO图片只能是 PNG 格式!');
                return
            }
            const result = await getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        this.meetingInfo.logo_transbg_url = "http://mainbucket.reachable-edu.com/" + res.key
                        console.log('url:' + this.meetingInfo.logo_transbg_url)
                    },
                }
                this.observable.subscribe(observer)
            }
        },
        async beforeLogoUpload(file) {
            const result = await getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        console.log('222')
                        this.meetingInfo.logo_url = "http://mainbucket.reachable-edu.com/" + res.key
                        console.log('url:' + this.meetingInfo.logo_url)
                    },
                }
                this.observable.subscribe(observer)
            }
        },
        searchmap(keywords) {
            var autoOptions = {
                city: "全国",
                pageIndex: 1,
                pageSize: 30,
            };
            var placeSearch = new window.AMap.PlaceSearch(autoOptions);
            placeSearch.search(keywords, (status, result) => {
                console.log(status, result);
                if (
                    result &&
                    result.poiList &&
                    result.poiList.pois &&
                    result.poiList.pois.length > 0
                ) {
                    const arr = [];
                    result.poiList.pois.forEach((item) => {
                        const ic = {
                            label: item.name,
                            value: item.location.lng + "," + item.location.lat,
                        };
                        arr.push(ic);
                    });
                    console.log("arr时", arr);
                    this.positionOptions = arr;
                } else {
                    this.positionOptions = [];
                }
                this.positionLoading = false;
            });
        },
        saveMeeting() {
            this.$refs.meetingForm.validate((val) => {
                if (val) {
                    this.meetingInfo.start_date = parseInt(this.meetingInfo.start_date / 1000)
                    if (this.meetingInfo.end_date) {
                        this.meetingInfo.end_date = parseInt(this.meetingInfo.end_date / 1000)
                    }
                    if (!this.meetingInfo.address) {
                        this.meetingInfo.address = this.meetingInfo.geo_address
                    }
                    if (!this.meetingInfo.address) {
                        this.$message.error('请输入地址')
                        return
                    }
                    //如果是单日会议，需要计算duration
                    if (this.meetingInfo.type == '单日会议') {
                        let endTimes = this.meetingInfo.end_time.split(':')
                        let endTotalSeconds = this.formatTime(endTimes[0]) * 60 * 60 + this.formatTime(endTimes[1]) * 60 + this.formatTime(endTimes[2])
                        let startTimes = this.meetingInfo.start_time.split(':')
                        let startTotalSeconds = this.formatTime(startTimes[0]) * 60 * 60 + this.formatTime(startTimes[1]) * 60 + this.formatTime(startTimes[2])
                        this.meetingInfo.duration = parseInt((endTotalSeconds - startTotalSeconds))
                    }
                    if (!this.meetingInfo.organizer_id) {
                        if(getUserType == 'organizer'){
                            this.meetingInfo.organizer_id = getUserId()
                        }else if(getUserType == 'admin'){
                            this.meetingInfo.organizer_id = this.$route.query.org_id;   
                        }
                        
                    }
                    updateMeeting(this.meetingInfo).then((res) => {
                        this.$message.success('保存成功')
                        if (this.meeting_id == '') {
                            let meeting_id = res.data.data._id.$id
                            this.meeting_id = meeting_id
                            this.$meeting.$emit('handleMeetingChanged', meeting_id)
                        }
                        this.fetchData()
                    })
                }
            })
        },
        formatTime(time) {
            // 如果为空，返回0
            if (!time) return 0
            return parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
        },
        formatTimeToString(time) {
            if (time >= 10) {
                return time
            }
            return '0' + time
        },
        initmap() {
            console.log("加载地图");
            this.amap1 = new window.AMap.Map("gaodemap", {
                resizeEnable: true,
                center: [121.405176, 31.032884],
                zoom: 12,
                zooms: [8, 16],
            });
        },
        changeselmap(e) {
            console.log(e);
            if (this.amap1) {
                if (this.amarker) {
                    this.amap1.remove(this.amarker);
                }
                this.amarker = new window.AMap.Marker({
                    position: e.split(","),
                });
                this.amap1.add(this.amarker);
                this.meetingInfo.lon_lat = e;
                this.amap1.setZoomAndCenter(14, e.split(","));
                this.getgeocoder(e.split(","));
            }
        },
        getgeocoder(lnglat) {
            geocoder.getAddress(lnglat, (status, result) => {
                console.log("根据经纬度查询地址", status, result);
                if (status === "complete" && result.regeocode) {
                    this.meetingInfo.geo_address = result.regeocode.formattedAddress;
                } else {
                    console.log("根据经纬度查询地址失败");
                }
            });
        },
        remoteMethod(query) {
            if (query) {
                this.positionLoading = true;
                this.searchmap(query);
            } else {
                this.positionOptions = [];
            }
        },
    }
})
</script>

<style></style>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}


.ql-container {
    height: 360px !important;
}

.mapsearch {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 200px;
    height: 40px;
    z-index: 20;
}

.logo-uploader {
    width: 178px;
    height: auto;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}

.logo-uploader2 {
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}
</style>